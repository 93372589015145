












































































.kiwi-remoji {
    position: absolute;
    width: 300px;
    height: 300px;
    background: rgba(0, 0, 0, 0.5);
    left: -310px;
    top: 0;
    overflow: auto;
    display: flex;
    border-radius: 5px;
}

.kiwi-remoji.totop {
    top: -268px;
}

@media screen and (max-width: 490px) {
    .kiwi-remoji {
        position: fixed;
        top: 50px;
        left: calc(50% - 150px);
    }
}

.kiwi-remoji-title {
    width: 100%;
    font-size: 0.7rem;
    text-transform: uppercase;
}

.kiwi-remoji-cat {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.kiwi-remoji-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5rem;
    padding: 5px;
    line-height: 1.5rem;
}

.kiwi-remoji-container {
    padding: 5px;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
}
