.kiwi-message-footer-remojis {
  color: #fff;
  display: inline-block;
}
.kiwi-message-remojis-list {
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0;
}
.kiwi-message-remojis-list li {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  line-height: 1.1rem;
  padding: 0.4rem 0.2rem 0.2rem 0.2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
}
.kiwi-message-remojis-list li .number {
  margin-left: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
}
.kiwi-message-remojis-list li:hover .number {
  color: #ffffff;
}
.kiwi-message-remojis-list li:not(:first-child) {
  margin-left: 5px;
}
