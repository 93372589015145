.kiwi-cinewindow {
  width: 60%;
}
.kiwi-cine {
  width: 100%;
  height: 100%;
  position: relative;
}
.kiwi-mobiledevice {
  display: none;
}
.kiwi-button-iw {
  position: absolute;
  bottom: 30px;
  right: 25px;
  background: #ff5757;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}
@media screen and (max-width: 769px) {
  .kiwi-cinewindow {
    width: 100%;
  }
  .kiwi-cine {
    display: none;
  }
  .kiwi-mobiledevice {
    display: block;
  }
}
