.kiwi-game {
  margin-bottom: 16px;
  cursor: pointer;
  flex: 0 0 272px;
  height: 127px;
  padding: 5px;
  max-width: 100%;
}
.kiwi-game img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.kiwi-gamelistwindow {
  width: 60%;
}
.kiwi-gamelistwrapper {
  background: #000;
  padding: 20px;
  height: 100%;
}
.kiwi-gamelist {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  background: #000;
  flex-wrap: wrap;
  justify-content: start;
  align-content: start;
}
@media screen and (max-width: 769px) {
  .kiwi-gamelistwindow {
    width: 100%;
  }
}
