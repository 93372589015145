


































































.kiwi-table-ignorelist {
    display: flex;
    flex-direction: column;
}

.kiwi-table-row {
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.kiwi-table-row:nth-child(2n) {
    background-color: rgba(255, 255, 255, 0.08);
}

.kiwi-ignorelist-user-center {
    min-width: 150px;
    max-width: 200px;
    width: 100%;
    text-align: left;
}

.kiwi-ignorelist-user-center a {
    color: #fff;
}

.kiwi-ignorelist-user-center a.u-gender-m {
    color: rgb(136, 193, 255);
}

.kiwi-ignorelist-user-center a.u-gender-f {
    color: rgb(251, 143, 251);
}

.kiwi-ignorelist-user-age {
    min-width: 50px;
    max-width: 150px;
    width: 100%;
}

.kiwi-ignorelist-userage-center {
    min-width: 100px;
    max-width: 200px;
    width: 100%;
    text-align: left;
}

.kiwi-channellist {
    box-sizing: border-box;
    text-align: center;
    transition: all 0.6s;
}

/* Input form styling */
.kiwi-channellist-nav .u-form {
    display: flex;
    justify-content: center;
}

.kiwi-channellist-nav .u-form .u-input {
    width: 324px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.kiwi-channellist-nav .u-form .u-button-primary,
.kiwi-channellist-nav .u-form .u-button-secondary {
    font-size: 1.3em;
    border-radius: 0 5px 5px 0;
    border: none;
    display: flex;
    align-items: center;
}

.kiwi-channellist-nav .u-form .u-button-primary i,
.kiwi-channellist-nav .u-form .u-button-secondary i {
    margin-left: -2px;
}

.kiwi-channellist-pagination {
    display: inline-block;
    margin: 20px auto 10px auto;
    font-size: 1.2em;
}

.kiwi-channellist-pagination a {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
}

.kiwi-channellist-search {
    display: inline-block;
}

.kiwi-channellist-info {
    text-align: center;
}

