











































































.kiwi-gif-list {
    display: flex;
    flex-wrap: wrap;
    flex-basis: fit-content;
}

.kiwi-gif-box {
    cursor: pointer;
    height: 100px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.kiwi-gif-box img {
    height: 100%;
    width: auto;
}

.kiwi-gif-simple-form.u-form input[type="text"] {
    width: 100%;
    border: 0;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0;
}

.kiwi-gif-simple-form.u-form input[type="text"]:focus {
    outline: none;
}

.kiwi-gif-simple-form {
    padding: 0;
    margin-top: -5px;
}

.kiwi-inputtool-gif-gif {
    display: block;
    float: left;
    height: 30px;
    width: 30px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 2px;
    background-size: contain;
}

