.kiwi-alertbox {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
.kiwi-alertbox .kiwi-textarea,
.kiwi-alertbox textarea {
  width: 100%;
}
.kiwi-alertbox textarea {
  border: 1px solid #fff;
  resize: none;
  padding: 5px;
}
.kiwi-alertbox a,
.kiwi-cred {
  text-decoration: none;
  color: #ff5757;
}
.kiwi-sidebar.kiwi-sidebar-section-user {
  right: 0;
  width: 380px;
}
.kiwi-userbox {
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}
.kiwi-userbox-selfprofile {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 1em;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.kiwi-userbox-header {
  position: relative;
  padding: 0.5em 1em;
  overflow: hidden;
}
.kiwi-userbox-header h3 {
  width: 100%;
  padding: 0;
  cursor: default;
  display: inline-block;
}
.kiwi-userbox-modestring {
  font-weight: normal;
  font-size: 0.8em;
}
.fa-user.kiwi-userbox-icon {
  display: inline-block;
  font-size: 2em;
}
.kiwi-userbox-usermask {
  width: 100%;
  opacity: 0.6;
  cursor: default;
}
.kiwi-userbox-basicinfo {
  width: 100%;
  margin: 0;
  display: block;
  padding: 0.5em 1em;
  box-sizing: border-box;
}
.kiwi-userbox-basicinfo-title,
.kiwi-userbox-basicinfo-data {
  display: block;
  width: 100%;
  cursor: default;
  margin: 0;
}
.kiwi-userbox-basicinfo-title {
  font-size: 1em;
  line-height: 1em;
  padding: 0;
  text-align: left;
  font-weight: 900;
}
.kiwi-userbox-basicinfo-data {
  margin-bottom: 1em;
  font-weight: normal;
  font-weight: 100;
  opacity: 1;
}
.kiwi-userbox-actions {
  width: 100%;
  padding: 1em;
  text-align: center;
  margin: 0;
  box-sizing: border-box;
}
.kiwi-userbox-actions .kiwi-userbox-action {
  display: inline-block;
  border: 1px solid;
  padding: 0.5em 1em;
  cursor: pointer;
  margin: 0 2px;
  transition: all 0.3s;
  border-radius: 3px;
}
.kiwi-userbox-actions label {
  display: block;
  cursor: pointer;
}
.kiwi-userbox-actions label span {
  text-align: left;
  width: auto;
}
.kiwi-userbox-opactions {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin: 0 0 1em 0;
  border-top: 1px solid;
  padding: 1em;
}
.kiwi-userbox-opactions label {
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  display: block;
  margin-bottom: 0.7em;
}
.kiwi-userbox-opactions label select {
  display: block;
  clear: both;
  padding: 10px;
  border-radius: 0.25em;
  box-shadow: none;
  border: 1px solid;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}
.kiwi-userbox-opaction {
  width: 100%;
  padding: 0 1em;
  text-align: left;
  border: none;
  line-height: 2.2em;
  font-size: 0.8em;
}
.kiwi-userbox-opaction i {
  margin-right: 0.2em;
  font-size: 1.2em;
}
.kiwi-userbox-actions a {
  margin-right: 1em;
}
.kiwi-userbox-whois {
  line-height: 1.4em;
  padding: 1em;
  width: 90%;
  margin: 0 5% 20px 5%;
  background: none;
  box-sizing: border-box;
  border-radius: 2px;
}
.kiwi-userbox-whois-line {
  display: block;
}
.kiwi-userbox-ignoreuser {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.kiwi-userbox-ignoreuser span {
  /* This fixes a vertical align issue between the checkbox and span */
  float: right;
}
.kiwi-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
@media screen and (max-width: 769px) {
  .kiwi-container--sidebar-drawn .kiwi-sidebar-userbox {
    width: 100%;
  }
  .kiwi-userbox {
    left: 0;
    right: 0;
    bottom: 40px;
    top: auto;
    max-width: 100%;
    border-width: 1px 0;
  }
  .kiwi-userbox .kiwi-userbox-header {
    padding-left: 10px;
  }
  .kiwi-userbox .kiwi-userbox-header i {
    display: none;
  }
  .kiwi-userbox .kiwi-userbox-basicinfo {
    padding: 10px 10px;
    margin-bottom: 20px;
  }
  .kiwi-userbox-actions {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .kiwi-userbox-actions .kiwi-userbox-action {
    width: 200px;
    clear: both;
    display: block;
    margin: 0 auto 20px auto;
  }
}
