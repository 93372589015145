









































.kiwi-gamewindow {
    width: 60%;
}

.kiwi-game {
    width: 100%;
    height: 100%;
    position: relative;
}

.kiwi-mobiledevice {
    display: none;
}

@media screen and (max-width: 769px) {
    .kiwi-gamewindow {
        width: 100%;
    }

    .kiwi-live {
        display: none;
    }

    .kiwi-mobiledevice {
        display: block;
    }
}
